import React from "react"
import styles from "./YoutubePlaylistDownloadForm.module.scss"

import {
  AutoCompleteInput,
  CheckboxInput,
  ChoiceSelector,
  ValueDesc,
  VD
} from "@damntools.fr/react-inputs"
import {
  DownloadPreset,
  PlaylistInfo,
  TrackInfo,
  YoutubeFormatAudioExt,
  YoutubeResourceType
} from "../../models"
import {PresetSelector, YoutubeUrlSelector} from "../selector"
import {AlertProvider, Notification} from "@damntools.fr/react-alert"
import {compare, List, Optionable, toList} from "@damntools.fr/types"
import {YoutubePlaylistDownloadFormConsumer} from "../../providers"
import {TimeUtils} from "@damntools.fr/utils-simple"
import {
  ContentBlock,
  FlexCell,
  FlexRowBlock,
  ShrinkFlexCell
} from "@damntools.fr/react-layout"
import {gif_loading, png_invalid, png_valid} from "../../../common"
import {StyleSize} from "@damntools.fr/react-utils"
import {YoutubePlaylistDownloadFormProviderState} from "../../providers/YoutubeDownloadFormProvider/YoutubeDownloadFormProvider.types"

const onChangeRelativePath = (
  value: Optionable<ValueDesc<string>>,
  setRelativePath: (value: Optionable<string>) => void
) => {
  setRelativePath(value.map(v => v.returnValue))
}

const getRelativePathValues = (subDirectories: List<string>) => {
  return subDirectories.stream().map(VD).collect(toList)
}

export const YoutubePlaylistDownloadForm = () => {
  return (
    <YoutubePlaylistDownloadFormConsumer>
      {context => {
        const relativePathValues = getRelativePathValues(context.subDirectories)
        return (
          <div className={styles.YoutubePlaylistDownloadForm}>
            <div className={styles.Form}>
              <div className={styles.Upper}>
                <div className={styles.UpperLeft}>
                  <ContentBlock
                    title={"Resource"}
                    titleIcon={getIcon(context.validation)}
                    iconSize={"20px"}
                    className={styles.ResourceZone}>
                    <div className={styles.FieldHelp}>
                      Paste here the full Youtube url or just the resource id.
                    </div>
                    <YoutubeUrlSelector
                      id={context._form.id}
                      onChangeId={context.setId}
                      type={YoutubeResourceType.PLAYLIST}
                    />
                  </ContentBlock>

                  <ContentBlock title={"Relative path"}>
                    <div className={styles.FieldHelp}>
                      A directory with playlist name will always be created and elements
                      puts in. You can choose the path in which the folder will be
                      created.
                    </div>
                    <AutoCompleteInput
                      dark
                      maxItems={4}
                      search={context._form.relativePath.orElse(() =>
                        context.info.map(i => i.title)
                      )}
                      placeholder={"sub/path "}
                      values={relativePathValues}
                      onChange={value =>
                        onChangeRelativePath(value, context.setRelativePath)
                      }
                    />
                  </ContentBlock>
                  <ContentBlock
                    title={`Presets (${DownloadPreset.all().size()})`}
                    canToggle>
                    <div className={styles.FieldHelp}>Only audio presets for now.</div>
                    <PresetSelector
                      value={context._form.preset}
                      onChange={context.setPreset}
                      onlyAudio
                      noIcon
                    />
                  </ContentBlock>
                  <ContentBlock title={"Audio"}>
                    <div>
                      <div className={styles.ConvertToAudio}>
                        <CheckboxInput
                          checked={context._form.convertToAudio.orElseReturn(false)}
                          dark
                          color={"#678a23"}
                          size={StyleSize.px(18)}
                          manageDisplayValueOutside
                          onChange={() =>
                            context.setConvertToAudio(
                              context._form.convertToAudio.map(c => !c)
                            )
                          }
                        />
                        <label>Convert to audio</label>
                      </div>
                      {context._form.convertToAudio.filter(b => b).isPresent() ? (
                        <ContentBlock title={"Audio format"}>
                          <ChoiceSelector
                            dark
                            selectedValues={context._form.audioFormat
                              .map(VD)
                              .toStream()
                              .collect(toList)}
                            values={getAudioFormatValues()}
                            onChange={context.setAudioFormat}
                          />
                        </ContentBlock>
                      ) : null}
                    </div>
                  </ContentBlock>
                </div>
                <div className={styles.UpperRight}>
                  <div className={styles.PlaylistInfoZone}>
                    {context.info
                      .map(i => getPlaylistElement(i, context))
                      .orElseUndefined()}
                  </div>
                </div>
              </div>
              <div className={styles.Bottom}>
                <div className={styles.Buttons}>
                  <span onClick={() => onSave(context.sendRequest)}>Download !</span>
                </div>
              </div>
            </div>
          </div>
        )
      }}
    </YoutubePlaylistDownloadFormConsumer>
  )
}

const onSave = (storeFn: () => Promise<string>) => {
  storeFn()
    .then(downloadId => {
      AlertProvider.submit(
        Notification.success("Download requested !")
          .Subtitle(`Id : ${downloadId}`)
          .Timeout(5000)
      )
    })
    .catch(err => {
      console.log("Error while requesting downloads", err)
      AlertProvider.submit(
        Notification.error().Subtitle("Error while requesting downloads")
      )
    })
}

const getAudioFormatValues = () => {
  return [
    ValueDesc.returns(YoutubeFormatAudioExt.MP3).Display("MP3 (worst)"),
    ValueDesc.returns(YoutubeFormatAudioExt.FLAC).Display("FLAC (best)")
  ]
}

const getPlaylistElement = (
  info: PlaylistInfo,
  context: YoutubePlaylistDownloadFormProviderState
): JSX.Element => {
  console.log(info.duration)
  const elementCount = info.videos
    .stream()
    .filter(t => !t.deletedResource && !t.privateResource)
    .count()
  const rejectedCount = info.videos.size() - elementCount
  return (
    <div key={"FormPlaylistInfo" + info.id} className={styles.PlaylistInfoEntry}>
      <h1 className={styles.Title}>
        <a target={"_blank"} href={`https://youtube.com/watch?v=${info.id}`}>
          {info.title}
        </a>
        <span>...</span>
        <span>
          ({elementCount} elements / {context._form.elementIds.size()} selected /{" "}
          {rejectedCount} unavailable)
        </span>
      </h1>
      <h2>
        <span className={styles.ToggleSelectButton} onClick={context.selectAll}>
          SELECT ALL
        </span>
        <span> / </span>
        <span className={styles.ToggleSelectButton} onClick={context.unselectAll}>
          UNSELECT ALL
        </span>
      </h2>
      {info.uploader ? (
        <div className={styles.Uploader}>
          <span>Uploader</span>
          <span>{info.uploader}</span>
          {info.uploaderId && (
            <span>
              (
              <a target={"_blank"} href={`https://youtube.com/${info.uploaderId}`}>
                {info.uploaderId}
              </a>
              )
            </span>
          )}
        </div>
      ) : null}
      <div className={styles.Duration}>
        <span>Duration</span>
        <span>{TimeUtils.secondsToDuration(info.duration)}</span>
      </div>
      <div className={styles.Tracks}>
        <div>
          {info.videos
            .stream()
            .filter(t => !t.deletedResource && !t.privateResource)
            .sort((a, b) => compare(a.title.toLowerCase(), b.title.toLowerCase()))
            .map(t =>
              getTrackElement(t, context.togglePlaylistElement, context._form.elementIds)
            )
            .collectArray()}
        </div>
      </div>
    </div>
  )
}

const getTrackElement = (
  info: TrackInfo,
  togglePlaylistElement: (track: TrackInfo) => void,
  elementIds: List<string>
): JSX.Element => {
  return (
    <div key={"FormTrackInfo" + info.id} className={styles.TrackInfoEntry}>
      <FlexRowBlock>
        <ShrinkFlexCell className={styles.TrackCheckbox}>
          <div>
            <CheckboxInput
              onChange={() => togglePlaylistElement(info)}
              checked={elementIds.includes(info.id)}
              dark
              // manageDisplayValueOutside
              color={"#21570b"}
              size={StyleSize.px(16)}
            />
          </div>
        </ShrinkFlexCell>
        <FlexCell grow={1} shrink={1} className={styles.TrackInfoZone}>
          <div>
            <h1 className={styles.TrackTitle}>
              <a target={"_blank"} href={`https://youtube.com/watch?v=${info.id}`}>
                {info.title}
              </a>
              <span>...</span>
              <span>{TimeUtils.secondsToDuration(info.duration)}</span>
            </h1>
            {info.uploader ? (
              <div className={styles.TrackUploader}>
                <span>Uploader</span>
                <span>{info.uploader}</span>
                {info.uploaderId && (
                  <span>
                    (
                    <a target={"_blank"} href={`https://youtube.com/${info.uploaderId}`}>
                      {info.uploaderId}
                    </a>
                    )
                  </span>
                )}
              </div>
            ) : null}
          </div>
        </FlexCell>
      </FlexRowBlock>
    </div>
  )
}
const getIcon = (validation: Optionable<string>): string | undefined => {
  if (validation.isPresent()) {
    if (validation.filter(v => v === "loading").isPresent()) return gif_loading
    else if (validation.filter(v => v === "valid").isPresent()) return png_valid
    else if (validation.filter(v => v === "invalid").isPresent()) return png_invalid
  }
}
