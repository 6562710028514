import React from "react"
import {
  ArrayList,
  Collectors,
  List,
  Lists,
  Optionable,
  Optional,
  toList
} from "@damntools.fr/types"
import {
  DownloadPreset,
  DownloadRequest,
  DownloadRequestCtor,
  PlaylistInfo,
  TrackInfo,
  YoutubeFormat,
  YoutubeFormatAudioExt,
  YoutubeResourceType
} from "../../models"
import {YoutubeService} from "../../services"
import {UrlHashParser, YoutubeFormatUtils} from "../../utils"
import {RichComponent} from "@damntools.fr/react-utils"
import {AlertProvider, Notification} from "@damntools.fr/react-alert"
import {
  FormConfig,
  YoutubePlaylistDownloadFormProviderState
} from "./YoutubeDownloadFormProvider.types"
import {ConnectionFailed} from "@damntools.fr/http"

export const YoutubePlaylistDownloadFormContext = React.createContext(
  {} as YoutubePlaylistDownloadFormProviderState
)

export const YoutubePlaylistDownloadFormConsumer =
  YoutubePlaylistDownloadFormContext.Consumer

export class YoutubePlaylistDownloadFormProvider extends RichComponent<
  any,
  YoutubePlaylistDownloadFormProviderState
> {
  constructor(props: any) {
    super(props)
    this.updateUrl = this.updateUrl.bind(this)
    this.checkUrl = this.checkUrl.bind(this)
  }

  protected getInitialState(): YoutubePlaylistDownloadFormProviderState {
    return {
      _form: this.getInitialForm(),
      setAudioFormat: this.setAudioFormat.bind(this),
      setConvertToAudio: this.setConvertToAudio.bind(this),
      setFormatCodes: this.setFormatCodes.bind(this),
      setId: this.setId.bind(this),
      setPreset: this.setPreset.bind(this),
      togglePlaylistElement: this.togglePlaylistElement.bind(this),
      unselectAll: this.unselectAll.bind(this),
      selectAll: this.selectAll.bind(this),
      setRelativePath: this.setRelativePath.bind(this),
      setTitle: this.setTitle.bind(this),
      sendRequest: this.sendRequest.bind(this),
      validation: Optional.empty(),
      info: Optional.empty(),
      subDirectories: new ArrayList()
    }
  }

  private getInitialForm(): FormConfig {
    return {
      elementIds: new ArrayList(),
      audioFormat: Optional.empty(),
      convertToAudio: Optional.empty(),
      formatCodes: new ArrayList(),
      id: Optional.empty(),
      preset: Optional.empty(),
      relativePath: Optional.empty(),
      title: Optional.empty()
    }
  }

  componentDidMount() {
    const urlHash = UrlHashParser.parse(window.location.hash)
    const _form = this.state._form
    urlHash.id.map(Optional.nullable).ifPresentDo(value => (_form.id = value))
    urlHash.preset.map(Optional.nullable).ifPresentDo(value => (_form.preset = value))
    urlHash.preset.ifPresentDo(value => {
      const conf = YoutubeFormatUtils.getPresetAssociatedConf(
        Optional.nullable(value),
        Lists.empty()
      )
      conf.convertToAudio.ifPresentDo(
        value => (_form.convertToAudio = Optional.nullable(value))
      )
      conf.audioFormat.ifPresentDo(
        value => (_form.audioFormat = Optional.nullable(value))
      )
      _form.formatCodes = conf.formatCodes || new ArrayList()
    })
    urlHash.relativePath
      .map(Optional.nullable)
      .ifPresentDo(value => (_form.relativePath = value))
    void this.stater()
      .applyState({_form})
      .then(() => this.checkUrl())
      .then(() => this.loadSubdirectories())
  }

  render() {
    return (
      <YoutubePlaylistDownloadFormContext.Provider value={this.state}>
        {this.props.children}
      </YoutubePlaylistDownloadFormContext.Provider>
    )
  }

  setAudioFormat(value: Optionable<YoutubeFormatAudioExt>) {
    const _form = this.state._form
    _form.audioFormat = value
    if (_form.preset.isPresent()) _form.preset = Optional.empty()
    return this.stater().applyState({_form}).then(this.updateUrl)
  }

  setConvertToAudio(value: Optionable<boolean>) {
    const _form = this.state._form
    _form.convertToAudio = value
    if (_form.preset.isPresent()) _form.preset = Optional.empty()
    return this.stater().applyState({_form}).then(this.updateUrl)
  }

  togglePlaylistElement(track: TrackInfo) {
    const _form = this.state._form
    let ids = this.state._form.elementIds.copy()
    if (ids.stream().find(id => id === track.id)) {
      ids = ids
        .stream()
        .filter(id => id !== track.id)
        .collect(toList)
    } else {
      ids.push(track.id)
    }
    _form.elementIds = ids
    return this.stater().applyState({_form})
  }

  selectAll() {
    const _form = this.state._form
    if (this.state.info.isPresent()) {
      const ids = this.state.info
        .get()
        .videos.stream()
        .filter(t => !t.deletedResource && !t.privateResource)
        .map(t => t.id)
        .collect(toList)
      _form.elementIds = ids
      return this.stater().applyState({_form})
    }
  }

  unselectAll() {
    const _form = this.state._form
    _form.elementIds = new ArrayList()
    return this.stater().applyState({_form})
  }

  setFormatCodes(value: List<YoutubeFormat>) {
    const _form = this.state._form
    _form.formatCodes = value
      .stream()
      .filterPresent()
      .map(f => f.code)
      .filterPresent()
      .collect(Collectors.toList)
    if (_form.preset.isPresent()) _form.preset = Optional.empty()
    return this.stater().applyState({_form}).then(this.updateUrl)
  }

  setId(value: Optionable<string>) {
    return this.updateSimple("id", value)
      .then(
        () =>
          new Promise(resolve =>
            this.setState(
              {
                validation: Optional.of("loading"),
                info: Optional.empty()
              },
              () => resolve(undefined)
            )
          )
      )
      .then(this.checkUrl)
  }

  setPreset(value: Optionable<DownloadPreset>) {
    let _form = this.state._form
    _form.preset = value
    _form = {
      ..._form,
      ...YoutubeFormatUtils.getPresetAssociatedConf(value, Lists.empty())
    }
    return this.stater().applyState({_form}).then(this.updateUrl)
  }

  setRelativePath(value: Optionable<string>) {
    return this.updateSimple("relativePath", value).then(() => this.loadSubdirectories())
  }

  loadSubdirectories() {
    return YoutubeService.get()
      .getSubDirectories("")
      .then(res => {
        this.logger.info(`Found ${res.size()} sub directories`)
        return new Promise(resolve =>
          this.setState({subDirectories: res}, () => resolve(res))
        )
      })
      .catch(err => {
        this.setState(
          {
            subDirectories: new ArrayList()
          },
          () => {
            if (!(err instanceof ConnectionFailed)) {
              this.logger.warn("Could not fetch sub directories !", err)
              AlertProvider.submit(
                Notification.error().Subtitle("Could not fetch sub directories !")
              )
            }
          }
        )
      })
  }

  setTitle(value: Optionable<string>) {
    return this.updateSimple("title", value)
  }

  private updateSimple(key: keyof FormConfig, value: Optionable<any> | List<any>) {
    const _form = this.state._form
    // @ts-ignore
    _form[key] = value
    return this.stater().applyState({_form}).then(this.updateUrl)
  }

  sendRequest() {
    const request: DownloadRequestCtor = {
      type: YoutubeResourceType.PLAYLIST,
      id: this.state._form.id.get(),
      formats: this.state._form.formatCodes
        .stream()
        .map(f => f)
        .collect(toList),
      onlyAudio: this.state._form.convertToAudio,
      audioFormat: this.state._form.audioFormat,
      title: this.state._form.title,
      relativePath: this.state._form.relativePath
        .map(r => r + "/" + this.state.info.map(i => i.title).orElseUndefined())
        .orElse(() => this.state.info.map(i => i.title)),
      playlistElementIds: this.state._form.elementIds
    }
    return YoutubeService.get()
      .requestDownload(new DownloadRequest(request))
      .then(downloadId => {
        request.id = downloadId
        return downloadId
      })
      .then(downloadId => this.processSuccessfulRequest(downloadId))
  }

  private processSuccessfulRequest(downloadId: string) {
    return downloadId
  }

  private updateUrl() {
    const _form = this.state._form
    const url = UrlHashParser.parse(window.location.hash)
    url.type = Optional.of(YoutubeResourceType.PLAYLIST)
    _form.id.ifPresentDo(v => (url.id = Optional.nullable(v)))
    _form.preset.ifPresentDo(v => (url.preset = Optional.nullable(v)))
    _form.relativePath.ifPresentDo(v => (url.relativePath = Optional.nullable(v)))
    window.location.hash = url.toString()
    return Promise.resolve(url)
  }

  // @ts-ignore
  private checkUrl() {
    const id = this.state._form.id
    if (id.isPresent() && id.get().trim() !== "") {
      return YoutubeService.get()
        .getResourceInfo(YoutubeResourceType.PLAYLIST, id.get())
        .catch(err => console.log(err))
        .then(res => {
          return new Promise(resolve => {
            console.warn(res)
            if (res instanceof PlaylistInfo) {
              const state = {
                info: Optional.of(res),
                _form: {
                  ...this.state._form,
                  elementIds: res.videos
                    .stream()
                    .filter(t => !t.deletedResource && !t.privateResource)
                    .map(t => t.id)
                    .collect(toList)
                },
                validation: Optional.of("valid")
              } as YoutubePlaylistDownloadFormProviderState
              this.setState(state, () => resolve(res))
            } else
              this.setState(
                {validation: Optional.of("invalid"), info: Optional.empty()},
                () => resolve(true)
              )
          })
        })
    }
    return Promise.resolve()
  }
}
