import React from "react"
import {ArrayList, defined, List, Lists, toList} from "@damntools.fr/types"
import {DownloadExec, PlaylistDownloadExec, TrackDownloadExec} from "../models"
import {YoutubeService} from "../services"
import {AlertProvider, Notification} from "@damntools.fr/react-alert"
import {ConnectionFailed} from "@damntools.fr/http"

export type YoutubeProviderState = {
  downloads: List<DownloadExec>
}

export const YoutubeContext = React.createContext({} as YoutubeProviderState)

export const YoutubeConsumer = YoutubeContext.Consumer

export class YoutubeProvider extends React.Component<any, YoutubeProviderState> {
  static INSTANCE: YoutubeProvider | null = null
  state: YoutubeProviderState = {downloads: new ArrayList()}
  private refreshInterval: any

  constructor(props: any) {
    super(props)
  }

  componentWillUnmount() {
    clearInterval(this.refreshInterval)
  }

  componentDidMount() {
    YoutubeProvider.INSTANCE = this
    this.refreshDownloads()
    this.refreshInterval = setInterval(this.refreshDownloads.bind(this), 5000)
  }

  static updateDownloadProgress(id: number, progress: number, rate: number, eta: number) {
    if (this.INSTANCE) {
      let changed = false
      const list = this.INSTANCE.state.downloads.copy().forEach(d => {
        if (d.id === id && d instanceof TrackDownloadExec) {
          if (defined(progress) && d.progression < progress) {
            d.progression = progress
            changed = true
          }
          if (defined(rate) && d.rate !== rate) {
            d.rate = rate
            changed = true
          }
          if (defined(eta) && d.eta < eta) {
            d.eta = eta
            changed = true
          }
        }
      })

      list.stream().peek(d => {
        if (d instanceof PlaylistDownloadExec) {
          d.tracks = d.tracks
            .stream()
            .flatMap(t =>
              list
                .stream()
                .filter(dd => dd.id === t.id)
                .map(dd => dd as TrackDownloadExec)
                .collectArray()
            )
            .collect(toList)
        }
      })
      if (changed) this.INSTANCE.setState({downloads: list})
    }
  }

  render() {
    return (
      <YoutubeContext.Provider value={this.state}>
        {this.props.children}
      </YoutubeContext.Provider>
    )
  }

  refreshDownloads() {
    YoutubeService.get()
      .getDownloads()
      .then(res => {
        console.warn(res.getInner())
        this.setState({downloads: res})
      })
      .catch(err => {
        this.setState({downloads: Lists.empty()}, () => {
          if (!(err instanceof ConnectionFailed)) {
            AlertProvider.submit(
              Notification.error().Subtitle("Could not get youtube downloads list")
            )
          }
        })
      })
  }
}
