import {ResourceInfo, ResourceInfoCtor, ResourceInfoDto} from "./ResourceInfo"
import {TrackInfo, TrackInfoDto} from "./TrackInfo"
import {ArrayList, List} from "@damntools.fr/types"

export type PlaylistInfoDto = ResourceInfoDto & {
  videos: Array<TrackInfoDto>
}

export type PlaylistInfoCtor = ResourceInfoCtor & {
  videos: List<TrackInfo>
}

export class PlaylistInfo extends ResourceInfo {
  videos: List<TrackInfo>

  constructor(obj: PlaylistInfoCtor) {
    super(obj)
    this.videos = obj.videos || new ArrayList()
  }
}
