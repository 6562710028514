import {
  DownloadRequest,
  DownloadRequestCtor,
  DownloadRequestDto,
  PlaylistInfo,
  PlaylistInfoDto,
  TrackInfo,
  TrackInfoDto,
  YoutubeFormatAudioExt,
  YoutubeResourceType
} from "../models"
import {YoutubeFormatMapper} from "./YoutubeFormatMapper"
import {ArrayList, Collectors, List, Lists, Optional} from "@damntools.fr/types"

export class YoutubeResourceInfoMapper {
  static INSTANCE: YoutubeResourceInfoMapper | null = null
  private readonly formatMapper: YoutubeFormatMapper

  constructor() {
    this.formatMapper = YoutubeFormatMapper.get()
  }

  trackFromDto(dto: TrackInfoDto) {
    return new TrackInfo({
      deletedResource: dto.deletedResource,
      duration: dto.duration,
      id: dto.id,
      privateResource: dto.privateResource,
      title: dto.title,
      uploader: dto.uploader,
      uploaderId: dto.uploaderId,
      viewCount: dto.viewCount,
      formats: this.formatMapper.fromDtos(dto.formats),
      thumbnail: dto.thumbnail,
      index: dto.index
    })
  }

  tracksFromDto(dtos: Array<TrackInfoDto>): List<TrackInfo> {
    if( !dtos) return new ArrayList()
    return Lists.from(dtos)
      .stream()
      .map(dto => this.trackFromDto(dto))
      .collect(Collectors.toList)
  }

  playlistFromDto(dto: PlaylistInfoDto) {
    return new PlaylistInfo({
      deletedResource: dto.deletedResource,
      duration: dto.duration,
      id: dto.id,
      privateResource: dto.privateResource,
      title: dto.title,
      uploader: dto.uploader,
      uploaderId: dto.uploaderId,
      videos: this.tracksFromDto(dto.videos)
    })
  }

  playlistsFromDto(dtos: Array<PlaylistInfoDto>): List<PlaylistInfo> {
    if( !dtos) return new ArrayList()
    return Lists.from(dtos)
      .stream()
      .map(dto => this.playlistFromDto(dto))
      .collect(Collectors.toList)
  }

  requestDownloadToDto(request: DownloadRequest): DownloadRequestDto {
    const dto = {
      formats: request.formats.getInner(),
      playlistElementIds: request.playlistElementIds.getInner(),
      id: request.id,
      resourceId: request.resourceId,
      type: request.type.key()
    } as DownloadRequestDto
    request.onlyAudio.ifPresentDo(v => (dto.onlyAudio = v))
    request.title.ifPresentDo(v => (dto.title = v))
    request.relativePath.ifPresentDo(v => (dto.relativePath = v))
    request.audioFormat.ifPresentDo(v => (dto.audioFormat = v.key().toUpperCase()))
    return dto
  }

  requestDownloadFromDto(request: DownloadRequestDto): DownloadRequest {
    const audioFormat =
      (request.audioFormat &&
        YoutubeFormatAudioExt.optionalFromValue<string, YoutubeFormatAudioExt>(
          request.audioFormat.toLowerCase()
        )) ||
      Optional.empty()
    const ctor: DownloadRequestCtor = {
      type: YoutubeResourceType.fromValue(request.type),
      id: request.id,
      onlyAudio: Optional.nullable(request.onlyAudio),
      audioFormat,
      resourceId: request.resourceId,
      title: Optional.nullable(request.title),
      relativePath: Optional.nullable(request.relativePath),
      formats: Lists.from(request.formats),
      playlistElementIds: Lists.from(request.playlistElementIds)
    }
    return new DownloadRequest(ctor)
  }

  static get(): YoutubeResourceInfoMapper {
    if (this.INSTANCE === null) this.INSTANCE = new YoutubeResourceInfoMapper()
    return this.INSTANCE
  }
}
